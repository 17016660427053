import { Card, Col, Divider, Row, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

const AdminPage = () => {
  const [reports, setReports] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getReports().then((data) => {
        setReports(data.data);
        console.info("new", data.data);
      });
    }
  }, [initialRender]);
  const tbcolumns = [
    {
      title: "Item",
      dataIndex: "name",
    },
    {
      title: "Count",
      dataIndex: "count",
    },
  ];
  return (
    <div>
      {reports && (
        <div
          style={{
            backgroundColor: "#f5f5f5",
            padding: "15px",
            border: "1px solid #ddd",
          }}
        >
          <Row gutter={[6, 6]} wrap>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => navigate("/admin/orders")}
              >
                <Statistic
                  title="Orders Total"
                  value={"$" + reports.ordersTotal.toFixed(2)}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => navigate("/admin/orders")}
              >
                <Statistic
                  title="Orders Count"
                  value={reports.ordersCount}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card className="stat-card" hoverable>
                <Statistic
                  title="Student Count"
                  value={reports.studentCount}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card className="stat-card" hoverable>
                <Statistic
                  title="Total Stationery Count"
                  value={reports.stationeryCount}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
          </Row>
          <Divider orientation="left">
            Ordered Textbook & Exercise Books
          </Divider>
          <Row gutter={[6, 6]} wrap>
            {reports.books.map((book, index) => (
              <Col xs={24} sm={12} md={8} lg={6} xl={6} key={index}>
                <Card
                  className="stat-card"
                  hoverable
                  onClick={() => navigate("/admin/orders")}
                >
                  <Statistic
                    title={book.name}
                    value={book.count}
                    valueStyle={{ fontWeight: "bold" }}
                  />
                </Card>
              </Col>
            ))}
          </Row>
          <Divider orientation="left">Ordered Stationery Packs</Divider>
          <Row gutter={[6, 6]} wrap>
            {reports.stationery.map((book, index) => (
              <Col xs={24} sm={12} md={8} lg={6} xl={6} key={index}>
                <Card
                  className="stat-card"
                  hoverable
                  onClick={() => navigate("/admin/orders")}
                >
                  <Statistic
                    title={book.name}
                    value={book.count}
                    valueStyle={{ fontWeight: "bold" }}
                  />
                </Card>
              </Col>
            ))}
          </Row>
          <Divider orientation="left">Campus Details</Divider>
          <Row gutter={[6, 6]} wrap>
            {reports.campuses.map((book, index) => (
              <Col xs={24} sm={12} md={8} lg={6} xl={6} key={index}>
                <Card className="stat-card" hoverable>
                  <Statistic
                    title={book.name}
                    value={book.count}
                    valueStyle={{ fontWeight: "bold" }}
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
