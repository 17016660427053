import { axios } from "./HttpClient";

export const AdminService = {
  login: (values) => {
    return axios.post("/staff/login", values);
  },
  getLevels: () => {
    return axios.get("/yearlevels");
  },
  newTextbook: (values) => {
    return axios.post("/textbooks", values);
  },
  getTextbooks: () => {
    return axios.get("/textbooks");
  },
  newExercisesbook: (values) => {
    return axios.post("/exercisesbooks", values);
  },
  getExercisesbooks: () => {
    return axios.get("/exercisesbooks");
  },
  newStationery: (values) => {
    return axios.post("/stationery", values);
  },
  getStationery: () => {
    return axios.get("/stationery");
  },
  newDetails: (values) => {
    return axios.post("/details", values);
  },
  getDetails: () => {
    return axios.get("/details");
  },
  getYearProducts: (id) => {
    return axios.get("/store/year/" + id);
  },
  finalizeOrder: (values) => {
    return axios.post("/neworders/sendFinalOrder", values);
  },
  getOrders: () => {
    return axios.get("/reports/orders");
  },
  getOrder: (id) => {
    return axios.get("/orders/" + id);
  },
  changeStatus: (id, status, trackingNo) => {
    return axios.put("/orders/" + id + "/status", {
      status: status,
      trackingNo: trackingNo,
    });
  },
  getReports: () => {
    return axios.get("/reports");
  },
  newVoucherOrder: (v) => {
    return axios.post("/voucher", v);
  },
  getVoucherOrders: () => {
    return axios.get("/voucher");
  },
};
