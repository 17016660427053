import React, { createContext, useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const initialState = {};
export const GlobalContext = createContext(initialState);
export const GlobalContextProvider = ({ children }) => {
  const [isAffix, setIsAffix] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [levels, setLevels] = useState([]);
  const [gidenData, setGidenData] = useState();
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getLevels().then((data) => {
        setLevels(data.data);
      });
    }
  }, [initialRender]);
  return (
    <GlobalContext.Provider
      value={{
        isAffix,
        setIsAffix,
        levels,
        gidenData,
        setGidenData,
        orders,
        setOrders,
        total,
        setTotal,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
