import React from "react";
import "react-quill/dist/quill.snow.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.less";
import AdminPage from "./components/admin/AdminPage";
import Exercisesbooks from "./components/admin/ExercisesBook";
import NewOrder from "./components/admin/NewOrder";
import OrderDetails from "./components/admin/OrderDetails";
import Orders from "./components/admin/Orders";
import PackPrices from "./components/admin/PackPrices";
import Stationery from "./components/admin/Stationery";
import Textbooks from "./components/admin/Textbooks";
import Contact from "./components/home/Contact";
import HomePage from "./components/home/HomePage";
import StorePage from "./components/home/StorePage";
import Submitted from "./components/home/Submitted";
import AdminLayout from "./layouts/AdminLayout";
import HomeLayout from "./layouts/HomeLayout";
import LoginLayout from "./layouts/LoginLayout";
import NotFound from "./layouts/NotFound";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route path="" element={<HomePage />} />
        <Route path="contact" element={<Contact />} />
        <Route path="store/:id" element={<StorePage />} />
        <Route path="submitted" element={<Submitted />} />

        <Route path="" element={<Navigate replace to="homepage" />} />
      </Route>
      <Route path="/home" element={<Navigate replace to="/" />} />
      <Route path="/home/homepage" element={<Navigate replace to="/" />} />

      <Route path="admin" element={<AdminLayout />}>
        <Route path="dashboard" element={<AdminPage />} />
        <Route path="textbooks" element={<Textbooks />} />
        <Route path="exercisesbooks" element={<Exercisesbooks />} />
        <Route path="stationery" element={<Stationery />} />
        <Route path="details" element={<PackPrices />} />
        <Route path="orders/:id/details" element={<OrderDetails />} />
        <Route path="orders/:filtre" element={<Orders />} />
        <Route path="orders" element={<Orders />} />
        <Route path="neworder" element={<NewOrder />} />

        <Route path="" element={<Navigate replace to="dashboard" />} />
      </Route>
      <Route path="login" element={<LoginLayout />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
