import Axios from "axios";
import { notification } from "antd";
import { axHistory } from "./axHistory";
import nProgress from "nprogress";

nProgress.configure({ showSpinner: false, trickleSpeed: 100 });

var axios = Axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
});
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.interceptors.request.use(
  function (config) {
    nProgress.remove();
    nProgress.start();
    if (!config.url.startsWith("http")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    nProgress.done();
    return response;
  },
  function (error) {
    nProgress.done();

    // 402 Payment Required / 3Ds secure needed
    if (error && error.response && error.response.status === 402) {
      return error.response; // return response;
    }

    if (
      [401, 402, 403].findIndex(
        (a) => a === parseInt(error.response.status)
      ) !== -1
    ) {
      notification.error({
        message: "Unauthorized action!",
        description: "Please log in again.",
      });

      axHistory.push("/login", { from: window.location.pathname });
    } else {
      console.error("axios error:", error.response);
      notification.error({
        message: "Error!",
        description: error.response.data.message
          ? `Error message: ${error.response.data.message}`
          : "Try again later",
      });
    }
    return Promise.reject(error);
  }
);
export { axios };
