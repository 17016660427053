import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  notification,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const NewOrder = () => {
  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const [orders, setOrders] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getVoucherOrders().then((data) => {
        console.log(data.data);
        setOrders(data.data);
      });
    }
  }, [initialRender]);
  const onFinish = (v) => {
    console.log(v);
    AdminService.newVoucherOrder(v).then((data) => {
      notification.success({ message: "Order saved successfully!" });
      setInitialRender(true);
    });

    onReset();
  };
  const onReset = () => {
    form.resetFields();
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "createdOn",
      render: (createdOn) => moment(createdOn).format("DD-MM-YYYY"),
    },
    {
      title: "Campus",
      dataIndex: "campus",
    },
    {
      title: "Year",
      dataIndex: "year",
      render: (year) => <span>{`Year-${year - 1}`}</span>,
    },
    {
      title: "Student",
      dataIndex: "fullname",
    },
    {
      title: "Stationery",
      dataIndex: "stationery",
    },
  ];
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Divider orientation="left">New Order With Voucher</Divider>
          <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            layout="vertical"
          >
            <Form.Item
              label="Campus"
              name="campus"
              rules={[{ required: true, message: "This filed is required!" }]}
            >
              <Select placeholder="Select campus">
                {["Prestons", "Illawarra", "Auburn", "Leppington"].map((y) => (
                  <Select.Option key={y} value={y}>
                    {y}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Year Level"
              name="year"
              rules={[{ required: true, message: "This filed is required!" }]}
            >
              <Select placeholder="Select year level">
                {[0, 1, 2, 3, 4, 5, 6].map((y) => (
                  <Select.Option key={y} value={y + 1}>
                    {y === 0 ? "KG" : y}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Student full name"
              name="fullname"
              rules={[{ required: true, message: "This filed is required!" }]}
            >
              <Input placeholder="Type student full name" />
            </Form.Item>
            <Form.Item
              label="Is The Stationery Pack Included?"
              name="stationery"
              rules={[{ required: true, message: "This filed is required!" }]}
            >
              <Select placeholder="Select stationery pack">
                {["Yes", "No"].map((y) => (
                  <Select.Option key={y} value={y}>
                    {y}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
                <Button htmlType="reset" icon={<ClearOutlined />}>
                  Reset
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Divider orientation="left">Orders with voucher</Divider>
          <Table
            dataSource={orders}
            columns={columns}
            bordered
            size="small"
            pagination={false}
            loading={orders === null}
          />
        </Col>
      </Row>
    </div>
  );
};

export default NewOrder;
