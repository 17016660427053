import { Button, Image } from "antd";
import React from "react";
import LightLogo from "../../assets/images/logo.png";
import Uniforms from "../../assets/images/un1.png";

const Submitted = () => {
  return (
    <div className="container mx-auto min-h-screen bg-white border border-gray-300 shadow-lg lg:mt-1">
      <div className="py-6  overflow-hidden lg:pb-24">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-300"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="text-center mt-10">
            <img src={LightLogo} className="h-16" alt="logo" />
            <div className="mt-4 lg:mt-0 w-full text-center">
              <div className="text-sky-500 font-bold text-3xl pt-6 text-center">
                Amity College Primary Resources 2024
              </div>
            </div>

            <p className="mb-10 text-2xl block text-green-500 font-bold">
              Your order has been successfully completed!
            </p>
            <p className="mb-10 text-2xl block text-gray-500 font-bold">
              Your order will be sent to the student's class at the start of
              school.
            </p>
            <p className="mb-10 text-2xl block text-green-500 font-bold">
              Thank you for your shopping.
            </p>
            <p className="mb-10 text-2xl block text-sky-500 font-bold">
              You can also get school uniforms from our website.
            </p>
            <Button
              className="uppercase !border-sky-700 !bg-sky-600 !font-bold"
              onClick={() =>
                window.open(
                  "https://lightbookstore.com.au/new-uniforms/",
                  "_self"
                )
              }
              size="large"
              type="primary"
            >
              Click For Uniform Shop
            </Button>
            <br />
            <br />
            <br />
            <Image
              src={Uniforms}
              preview={false}
              className="w-full max-w-xl mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Submitted;
