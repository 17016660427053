import { createBrowserHistory } from "history";
import React from "react";
import { Router } from "react-router-dom";
export const axHistory = createBrowserHistory();
export const HistoryRouter = ({ children, history }) => {
  let [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};
