import React from "react";

const Contact = () => {
  return (
    <div>
      <span className="underline decoration-red-500 decoration-wavy">
        Contact
      </span>
    </div>
  );
};

export default Contact;
