import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const Exercisesbooks = () => {
  const [form] = Form.useForm();
  const { lg } = useBreakpoint();
  const { levels } = useContext(GlobalContext);
  const [exercisesbooks, setExercisesbooks] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getExercisesbooks().then((data) => {
        setExercisesbooks(data.data);
      });
    }
  }, [initialRender]);
  const onFinish = (v) => {
    AdminService.newExercisesbook(v).then((data) => {
      notification.success({ message: "Exercisesbook saved!" });
      onReset();
      setInitialRender(true);
    });
  };
  const onReset = () => {
    form.resetFields();
  };

  const columns = [
    {
      title: "Year Level",
      dataIndex: "yearlevel",
    },
    {
      title: "Subject",
      dataIndex: "subject",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Colour",
      dataIndex: "colour",
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price) => <span>${price.toFixed(2)}</span>,
    },
  ];
  return (
    <div className="m-4">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={44} md={24} lg={24} xl={8} xxl={8}>
          <div className="border border-gray-300 p-4 bg-gray-50">
            <Divider orientation="left">New Exercisesbook</Divider>
            <Form
              form={form}
              onFinish={onFinish}
              onReset={onReset}
              layout={lg ? "horizontal" : "vertical"}
              {...GlobalVariables.formLayout}
            >
              <Form.Item
                label="Year Level"
                name="yearlevelId"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Select placeholder="Please select year level">
                  {levels.map((level, index) => (
                    <Select.Option key={index} value={level.id}>
                      {level.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Please type book subject" />
              </Form.Item>

              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Please type book title" />
              </Form.Item>
              <Form.Item
                label="Quantity"
                name="quantity"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <InputNumber min={1} max={10} />
              </Form.Item>
              <Form.Item
                label="Colour"
                name="colour"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Please type colour" />
              </Form.Item>
              <Form.Item label="Price" name="price">
                <InputNumber addonBefore="$" min={0} max={100} />
              </Form.Item>
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
                className="mt-6"
              >
                <Space>
                  <Button
                    type="default"
                    htmlType="reset"
                    icon={<ClearOutlined />}
                  >
                    Clear Form
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col xs={24} sm={44} md={24} lg={24} xl={16} xxl={16}>
          <div className="border border-gray-300 p-4 bg-gray-50 ">
            <Divider orientation="left">Saved Exercisesbooks</Divider>
            <Table
              rowKey="id"
              dataSource={exercisesbooks}
              bordered
              size="small"
              columns={columns}
              loading={exercisesbooks === null}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Exercisesbooks;
