import {
  BookOutlined,
  DashboardOutlined,
  DollarOutlined,
  HeartOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Button, Col, Layout, Menu, Row, Tag } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import LightLogo from "../assets/images/logo.png";

const AdminLayout = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { lg, sm } = useBreakpoint();
  const [isSider, setIsSider] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsSider(lg ? false : true);
  }, [lg]);
  return (
    <Layout>
      <Header
        style={{
          height: "75px",
          lineHeight: "75px",
          position: lg ? "fixed" : "unset",
          zIndex: 1,
          width: "100%",
          backgroundColor: "white",
          borderBottom: "1px solid #ccc",
          boxShadow: "1px 1px 10px #ddd",
        }}
      >
        <Row justify={!sm ? "start" : "space-between"} align="middle">
          <Col>
            <Button
              icon={isSider ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              size="large"
              onClick={() => setIsSider(!isSider)}
            />
          </Col>
          <Col>
            <img
              onClick={() => navigate("/")}
              src={LightLogo}
              alt="logo"
              style={{
                maxWidth: "120px",
                marginLeft: !sm ? "20px" : "",
                cursor: "pointer",
              }}
            />
          </Col>
          {lg && (
            <Col>
              <Button
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                }}
                type="primary"
                icon={<LogoutOutlined />}
              >
                Log Out
              </Button>
            </Col>
          )}
        </Row>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          collapsible
          collapsed={isSider}
          theme="light"
          className={!lg ? "mobil-sider" : "desktop-sider"}
        >
          <br />
          <Tag
            color="blue"
            style={{
              width: "calc(100% - 40px)",
              textAlign: "center",
              margin: "5px 20px 0 20px",
            }}
          >
            Admin Menu
          </Tag>
          <Menu
            className="amenu"
            onSelect={() => {
              !lg && setIsSider(true);
            }}
            theme="light"
            mode="inline"
            defaultSelectedKeys={[location.pathname.split("/").pop()]}
          >
            <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
              <Link to="/admin/dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="textbooks" icon={<BookOutlined />}>
              <Link to="/admin/textbooks">Textbooks</Link>
            </Menu.Item>
            <Menu.Item key="exercisesbooks" icon={<BookOutlined />}>
              <Link to="/admin/exercisesbooks">Exercise Books</Link>
            </Menu.Item>
            <Menu.Item key="stationery" icon={<BookOutlined />}>
              <Link to="/admin/stationery">Stationery</Link>
            </Menu.Item>
            <Menu.Item key="details" icon={<DollarOutlined />}>
              <Link to="/admin/details">Pack Prices & Notes</Link>
            </Menu.Item>
            <Menu.Item key="orders" icon={<ShoppingCartOutlined />}>
              <Link to="/admin/orders">Orders</Link>
            </Menu.Item>
            <Menu.Item key="neworder" icon={<PlusOutlined />}>
              <Link to="/admin/neworder">New Order</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content
          style={{
            marginTop: lg ? "75px" : undefined,
            padding: "10px",
            borderLeft: "1px solid #ddd",
            minHeight: "calc(100vh - 150px)",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      <Footer className="footer">
        <div>
          <p className="text-small text-gray-400 text-center">
            <span className="text-orange-500 font-semibold">
              &copy; Light Bookstore
            </span>
            {", "}2022 . All rights reserved. <br />
            Developed by{" "}
            <a href="https://softmile.com.au" target="_blank" rel="noreferrer">
              {" "}
              Softmile
            </a>{" "}
            with <HeartOutlined /> React
          </p>
        </div>
      </Footer>
    </Layout>
  );
};

export default AdminLayout;
