import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const Orders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState(null);
  const { filtre } = useParams();
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  const searchInput = useRef();
  useEffect(() => {
    AdminService.getOrders().then((data) => {
      switch (filtre) {
        case "All":
          setOrders(data.data);
          break;
        case "Waiting":
          setOrders(data.data.filter((s) => s.status === "Waiting"));
          break;
        case "Processing":
          setOrders(data.data.filter((s) => s.status === "Processing"));
          break;
        case "Partially":
          setOrders(data.data.filter((s) => s.status === "Partially shipped"));
          break;
        case "Shipped":
          setOrders(data.data.filter((s) => s.status === "Shipped"));
          break;
        case "Cancelled":
          setOrders(data.data.filter((s) => s.status === "Cancelled"));
          break;
        default:
          setOrders(data.data);
          break;
      }
    });
  }, [filtre]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ ...state, searchText: "" });
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) => text,
  });

  const filtreChanged = (v) => {
    navigate("/admin/orders/" + v.target.value);
  };
  const columns = [
    {
      title: "Order Id",
      dataIndex: "id",
      className: "w-32 text-center text-base font-semibold",
      ...getColumnSearchProps("id"),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Order Date",
      dataIndex: "createdOn",
      render: (createdOn) => moment(createdOn).format("DD-MM-YY ddd HH:mm"),
    },
    {
      title: "Campus",
      dataIndex: "campus",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      ...getColumnSearchProps("customer"),
      sorter: (a, b) => GlobalVariables.compare(a.customer, b.customer),
    },
    {
      title: "Student",
      dataIndex: "studentName",
      ...getColumnSearchProps("studentName"),
      sorter: (a, b) => GlobalVariables.compare(a.studentName, b.studentName),
    },

    {
      title: "Phone",
      dataIndex: "phone",
    },

    {
      title: "Y.Level",
      dataIndex: "yearLevel",
    },
    {
      title: "Stationery",
      dataIndex: "spack",

      render: (spack) => (
        <span className={spack ? "text-green-500" : "text-red-500"}>
          {spack ? "Yes" : "No"}
        </span>
      ),
      sorter: (a, b) =>
        GlobalVariables.compare(a.spack.toString(), b.spack.toString()),
    },
    {
      title: "Total",
      dataIndex: "studentTotal",
      render: (studentTotal) => (
        <span className="text-base font-semibold">
          ${studentTotal.toFixed(2)}
        </span>
      ),
      className: "text-right w-24",
    },
  ];

  return (
    <div className="max-w-6xl mx-auto">
      {/* <Radio.Group
        defaultValue={filtre}
        buttonStyle="solid"
        onChange={filtreChanged}
      >
        <Radio.Button value="All">All</Radio.Button>
        <Radio.Button value="Waiting">Waiting</Radio.Button>
        <Radio.Button value="Processing">Processing</Radio.Button>
        <Radio.Button value="Partially">Partially Shipped</Radio.Button>
        <Radio.Button value="Shipped">Shipped</Radio.Button>
        <Radio.Button value="Cancelled">Cancelled</Radio.Button>
      </Radio.Group> */}
      <br />
      <Table
        rowKey="id"
        size="small"
        bordered
        dataSource={orders}
        columns={columns}
        className="border border-gray-300"
        pagination={false}
      />
    </div>
  );
};

export default Orders;
