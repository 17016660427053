export const GlobalVariables = {
  formLayout: {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  },
  tailLayout: {
    wrapperCol: {
      offset: 6,
      span: 18,
    },
  },
  adetler: [
    {
      key: "No Need",
      value: 0,
    },
    {
      key: "1",
      value: 1,
    },
    {
      key: "2",
      value: 2,
    },
    {
      key: "3",
      value: 3,
    },
  ],
  compare: (a, b) => {
    var atitle = a || "";
    var btitle = b || "";
    var alfabe =
      "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
    if (atitle.length === 0 || btitle.length === 0) {
      return atitle.length - btitle.length;
    }
    for (var i = 0; i < atitle.length && i < btitle.length; i++) {
      var ai = alfabe.indexOf(atitle[i]);
      var bi = alfabe.indexOf(btitle[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  },
};
