import { PrinterOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Descriptions,
  Tag,
  Button,
  Space,
  Select,
  notification,
  Input,
} from "antd";
import moment from "moment";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { AdminService } from "src/resources/AdminService";
import LightLogo from "../../assets/images/logo.png";

const OrderDetails = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [status, setStatus] = useState("Waiting");
  const [trackingNo, setTrackingNo] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getOrder(id).then((data) => {
        setStatus(data.data.status);
        setTrackingNo(data.data.tracking);
        setOrder(data.data);
      });
    }
  }, [id, initialRender]);

  const optionslar = [
    { key: "Waiting", value: "Waiting" },
    { key: "Processing", value: "Processing" },
    { key: "Partially shipped", value: "Partially shipped" },
    { key: "Shipped", value: "Shipped" },
    { key: "Cancelled", value: "Cancelled" },
  ];

  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div className="bg-white border border-gray-300 px-4 py-6 my-4">
        {order && (
          <div ref={ref} className="max-w-7xl mx-auto">
            <img
              src={LightLogo}
              alt="logo"
              style={{
                maxWidth: "120px",
              }}
            />{" "}
            <br />
            <Descriptions
              column={3}
              bordered
              title={
                <div>
                  <p>{`Order - #${order.id} details`}</p>
                  <div>
                    <span>Total: ${order.total}</span>
                    <br />
                    <span className="text-sm text-gray-600 font-normal">
                      Delivery Price: ${order.deliveryPrice}
                    </span>
                  </div>
                </div>
              }
              size="small"
              extra={
                <Space>
                  <span>
                    Order Date:{" "}
                    <span className="text-base font-semibold text-sky-500">
                      {moment(order.cratedOn).format("DD-MM-YY ddd HH:mm")}
                    </span>
                    {" / "}
                    Status:{" "}
                  </span>
                  <Tag
                    color={
                      order.status === "Waiting"
                        ? "orange"
                        : order.status === "Shipped"
                        ? "green"
                        : order.status === "Cancelled"
                        ? "red"
                        : "processing"
                    }
                  >
                    {order.status}
                  </Tag>
                </Space>
              }
            >
              <Descriptions.Item label="Customer Name">
                {order.fullname}
              </Descriptions.Item>
              <Descriptions.Item label="Student Name">
                {order.student}
              </Descriptions.Item>
              <Descriptions.Item label="Year Level">
                <span className="text-base font-semibold">
                  {order.yearlevel}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Phone">{order.phone}</Descriptions.Item>
              <Descriptions.Item label="Email">{order.email}</Descriptions.Item>
              <Descriptions.Item label="Delivery Option">
                <span className="text-base font-semibold">
                  {order.deliveryOption}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {order.address}
              </Descriptions.Item>
              <Descriptions.Item label="Suburb">
                {order.suburb}
              </Descriptions.Item>
              <Descriptions.Item label="Postcode">
                {order.postcode}
              </Descriptions.Item>
            </Descriptions>
            <br />
            <br />
            <Descriptions
              column={1}
              bordered
              title="Order Products"
              size="small"
            >
              {order.orderbooks.map((o, index) => (
                <Descriptions.Item
                  key={index}
                  label={
                    <Space>
                      <span>{o.subject}</span> - <span>{o.title}</span> -
                      <span>{order.yearlevel}</span> /{" "}
                      <span className="text-base font-semibold">
                        {o.quantity} qty
                      </span>
                    </Space>
                  }
                >
                  <span className="text-base font-semibold">
                    {" "}
                    ${(o.quantity * o.price).toFixed(2)}
                  </span>
                </Descriptions.Item>
              ))}
              <Descriptions.Item
                label={<span>Exercise Books Pack - {order.yearlevel}</span>}
              >
                {" "}
                <span className="text-base font-semibold">
                  {" "}
                  ${order.ebooksPackPrice.toFixed(2)}
                </span>
              </Descriptions.Item>
              {order.stationery && (
                <Descriptions.Item
                  label={<span>Stationery Pack - {order.yearlevel}</span>}
                >
                  {" "}
                  <span className="text-base font-semibold">
                    {" "}
                    ${order.statineryPackPrice.toFixed(2)}
                  </span>
                </Descriptions.Item>
              )}
            </Descriptions>
            <br />
            <br />
            <Descriptions
              column={1}
              bordered
              title="Order Status History"
              className="max-w-2xl"
              size="small"
            >
              {order.slogs.map((o, index) => (
                <Descriptions.Item
                  key={index}
                  label={moment(o.createdOn).format("dddd MMM DD,  HH:mm")}
                >
                  {o.status}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </div>
        )}
      </div>
    );
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const statusChanged = (v) => {
    if (v !== "Shipped") {
      setTrackingNo(null);
    }
    setStatus(v);
  };
  const trackNoChanged = (e) => {
    setTrackingNo(e.target.value);
  };
  const saveChange = () => {
    AdminService.changeStatus(order.id, status, trackingNo).then((data) => {
      notification.success({ message: "Status Changed!" });
      setInitialRender(true);
    });
  };
  return (
    <div className="max-w-7xl mx-auto m-4">
      <ComponentToPrint ref={componentRef} />
      <br />

      {order && (
        <Space>
          {" "}
          <span className="text-base font-semibold">Change Status:</span>
          <Select
            className="w-48 "
            size="large"
            value={status}
            placeholder="Select Status"
            onChange={statusChanged}
            options={optionslar}
          ></Select>
          {(status === "Shipped" || status === "Partially shipped") && (
            <Input
              placeholder="Tracking Number"
              className="w-32"
              onChange={trackNoChanged}
              size="large"
              value={trackingNo}
            />
          )}
          <Button
            type="primary"
            size="large"
            icon={<SaveOutlined />}
            onClick={() => saveChange()}
          >
            Save
          </Button>
        </Space>
      )}
      <br />
      <br />
      <Button
        size="large"
        icon={<PrinterOutlined />}
        type="primary"
        onClick={handlePrint}
        className="mr-2"
      >
        Print this Page
      </Button>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default OrderDetails;
