import { HeartOutlined } from "@ant-design/icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";

const HomeLayout = () => {
  const stripePromise = useMemo(
    () =>
      loadStripe(
        "pk_live_cw8U6j309SGBGZ14lWSgQuAo"
        //"pk_test_51JBXTjE8cXeCmmwbFKaTWIqMXshpGqkklyiaTWUvebeZrdZc999fblpssMaDamjk8FtMayhuzkVzg0G3GRUFAVob007gYJ4aI6"
      ),
    []
  );

  return (
    <div className="site-layout-content lg:py-4 footer">
      <Elements stripe={stripePromise}>
        <Outlet />
      </Elements>
      <div className="border-t border-gray-200 pt-4 ">
        <p className="text-small text-gray-400 text-center ">
          <span className="text-orange-500 font-semibold">
            &copy; Light Bookstore
          </span>
          {", "}2022 . All rights reserved. <br />
          Developed by{" "}
          <a href="https://softmile.com.au" target="_blank">
            {" "}
            Softmile
          </a>{" "}
          with <HeartOutlined /> React
        </p>
      </div>
    </div>
  );
};

export default HomeLayout;
