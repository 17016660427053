import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "src/context/GlobalContext";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const { setIsAffix } = useContext(GlobalContext);

  useEffect(() => {
    setIsAffix(false);
    window.scrollTo(0, 0);
  }, [pathname, setIsAffix]);

  return null;
}
