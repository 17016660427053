import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const Textbooks = () => {
  const [form] = Form.useForm();
  const { lg } = useBreakpoint();
  const { levels } = useContext(GlobalContext);
  const [textbooks, setTextbooks] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getTextbooks().then((data) => {
        setTextbooks(data.data);
      });
    }
  }, [initialRender]);
  const onFinish = (v) => {
    AdminService.newTextbook(v).then((data) => {
      notification.success({ message: "Textbook saved!" });
      onReset();
      setInitialRender(true);
    });
  };
  const onReset = () => {
    form.resetFields();
  };

  const columns = [
    {
      title: "Year Level",
      dataIndex: "yearlevel",
    },
    {
      title: "Subject",
      dataIndex: "subject",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Author",
      dataIndex: "author",
    },
    {
      title: "Publisher",
      dataIndex: "publisher",
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price) => <span>${price.toFixed(2)}</span>,
    },
  ];
  return (
    <div className="m-4">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={44} md={24} lg={24} xl={8} xxl={8}>
          <div className="border border-gray-300 p-4 bg-gray-50">
            <Divider orientation="left">New Textbook</Divider>
            <Form
              form={form}
              onFinish={onFinish}
              onReset={onReset}
              layout={lg ? "horizontal" : "vertical"}
              {...GlobalVariables.formLayout}
            >
              <Form.Item
                label="Year Level"
                name="yearlevelId"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Select placeholder="Please select year level">
                  {levels.map((level, index) => (
                    <Select.Option key={index} value={level.id}>
                      {level.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Please type book subject" />
              </Form.Item>

              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Please type book title" />
              </Form.Item>
              <Form.Item label="Author" name="author">
                <Input placeholder="Please type book author" />
              </Form.Item>
              <Form.Item
                label="Publisher"
                name="publisher"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Please type book publisher" />
              </Form.Item>
              <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <InputNumber addonBefore="$" min={0} max={100} />
              </Form.Item>
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
                className="mt-6"
              >
                <Space>
                  <Button
                    type="default"
                    htmlType="reset"
                    icon={<ClearOutlined />}
                  >
                    Clear Form
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col xs={24} sm={44} md={24} lg={24} xl={16} xxl={16}>
          <div className="border border-gray-300 p-4 bg-gray-50 ">
            <Divider orientation="left">Saved Textbooks</Divider>
            <Table
              rowKey="id"
              dataSource={textbooks}
              bordered
              size="small"
              columns={columns}
              loading={textbooks === null}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Textbooks;
// public string subject { get; set; }
// public string title { get; set; }
// public string author { get; set; }
// public string publisher { get; set; }
// public decimal price { get; set; }
