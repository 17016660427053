import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  notification,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const PackPrices = () => {
  const { levels } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const [details, setDetails] = useState(null);
  const { lg } = useBreakpoint();
  const [quillValue, setQuillValue] = useState("");
  function createMarkup(gelenDetay) {
    return {
      __html: gelenDetay,
    };
  }
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getDetails().then((data) => {
        setDetails(data.data);
      });
    }
  }, [initialRender]);
  const onFinish = (v) => {
    v = { ...v, note: quillValue };
    AdminService.newDetails(v).then((data) => {
      notification.success({ message: "Details saved!" });
      onReset();
      setInitialRender(true);
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const columns = [
    {
      title: "Year Level",
      dataIndex: "yearlevel",
    },

    {
      title: "Package Name",
      dataIndex: "group",
    },
    {
      title: "Price",
      dataIndex: "packprice",
      render: (packprice) => <span>${packprice}</span>,
    },
    {
      title: "Notes",
      dataIndex: "note",
      render: (note) => <div dangerouslySetInnerHTML={createMarkup(note)} />,
    },
  ];
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const quillChange = (e) => {
    setQuillValue(e);
  };
  return (
    <div className="m-4">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <div className="border border-gray-300 p-4 bg-gray-50">
            <Divider orientation="left">New Item</Divider>
            <Form
              form={form}
              onFinish={onFinish}
              onReset={onReset}
              layout={lg ? "horizontal" : "vertical"}
              {...GlobalVariables.formLayout}
            >
              <Form.Item
                label="Year Level"
                name="yearlevelId"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Select placeholder="Please select year level">
                  {levels.map((level, index) => (
                    <Select.Option key={index} value={level.id}>
                      {level.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Package Name"
                name="group"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Select placeholder="Select Package">
                  {["Exercise", "Stationery"].map((g, index) => (
                    <Select.Option key={index} value={g}>
                      {g}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Pack Price"
                name="packprice"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <InputNumber addonBefore="$" min={0} max={100} />
              </Form.Item>
              <Form.Item label="Package Notes">
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  onChange={(e) => quillChange(e)}
                  placeholder="Please type notes"
                />
              </Form.Item>
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
                className="mt-6"
              >
                <Space>
                  <Button
                    type="default"
                    htmlType="reset"
                    icon={<ClearOutlined />}
                  >
                    Clear Form
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
          <div className="border border-gray-300 p-4 bg-gray-50 ">
            <Divider orientation="left">Saved Items</Divider>
            <Table
              rowKey="id"
              dataSource={details}
              bordered
              size="small"
              columns={columns}
              loading={details === null}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PackPrices;
